<template>
  <div class="inside-os report-os">
    <div class="com-title">
      <span>赔率变动表

      </span>
      <div class="tab_div">
        <span class="selected">二定位</span>
        <span>三定位</span>
        <span>四定位</span>
        <span>二字现</span>
        <span>三字现</span>
        <span>四字现</span>
      </div>
      <span></span>
    </div>
    <div class="report">
      <ul class="custome_ul">
        <li>
          <span class="head">号码</span>
          <span class="head ">赔率</span>
        </li>
        <li>
          <span class="head">号码</span>
          <span class="head">赔率</span>
        </li>
        <li>
          <span class="head">号码</span>
          <span class="head">赔率</span>
        </li>
        <li>
          <span class="head">号码</span>
          <span class="head">赔率</span>
        </li>
        <li>
          <span class="head">号码</span>
          <span class="head">赔率</span>
        </li>
        <li>
          <span class="head">号码</span>
          <span class="head">赔率</span>
        </li>
        <li class="li" v-for="i in 100">
          <span>{{i}}XXXXX</span>
          <span>9.0909</span>
        </li>

      </ul>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "plbdb",
  props: [""],
  data() {
    return {
      // 合计
      tAmount: 0,
      tTuishui: 0,
      tKymoney: 0,
      // 条查
      queryParam: {},
      // state(订单状态:0 未结算 1 已结算 3 系统退单 4 用户撤单 -1 全部)
      state: 0,
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      totalPage: 1,
      totals: 1,
      list: [],
    };
  },

  components: {},
  created() {
    this.getList();
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  methods: {
    getList() {
      this.tAmount = 0;
      this.tTuishui = 0;
      this.tKymoney = 0;
      let begindate = this.splitDate(this.getBeforeDate(0));
      let enddate = this.splitDate(this.getBeforeDate(0));
      let obj = {
        uuid: this.userInfo.Uuid,
        // uuid: 10002012,
        sid: this.userInfo.Sid,
        state: this.state,
        pagenum: this.pages.pagenum,
        pagecount: this.pages.pagecount,
        begindate,
        enddate,
      };
      this.$http
        .post("getlotorderinfos", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);
              // this.list = resData;
              this.list = resObj.data;
              // console.log(this.list);
              this.list.forEach((item) => {
                this.tAmount += Number(item.amount);
                this.tTuishui += Number(item.tuishui);
                item.kymoney = (
                  Number(item.amount) * Number(item.winodds) -
                  Number(item.amount) +
                  Number(item.tuishui)
                ).toFixed(2);
                this.tKymoney += Number(item.kymoney);
              });

              this.totalPage = resObj.maxpagenum;
              this.totals = resObj.maxrecordnum;
              console.log(this.list, 5643);
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      this.pages.pagecount = val;
      this.pages.pagenum = 1;
      this.getList();
    },
    splitDate(time) {
      let arr = time.split("-");
      let str = "";
      arr.forEach((element) => {
        str += element;
      });
      return str;
    },
    // 当前页面
    handleCurrentChange(page) {
      this.pages.pagenum = page;
      this.getList();
    },
  },
};
</script>
<style  scoped>
.custome_ul li:nth-of-type(6n) {
  /* border-right: none; */
}
.custome_ul li span:nth-of-type(1) {
  border-right: 1px solid lightgray;
}
.custome_ul .li:nth-of-type(2n) {
  /* background: rgb(253, 162, 6); */
}
.custome_ul li span.head {
  background: #e4e4e4;
}
.custome_ul li span {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custome_ul li {
  width: 16.66%;
  height: 30px;
  display: flex;

  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  box-sizing: border-box;
}
.custome_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  /* height: 310px; */
  border: 1px solid lightgray;
  border-bottom: none;
  border-right: none;
  box-sizing: border-box;
  /* overflow-y: scroll; */
}
.tab_div span {
  display: inline-block;
  margin: 0 4px;
}
.tab_div span.selected {
  color: red;
}
.green {
  color: green;
}
.blue {
  color: blue;
}
.red {
  color: red;
  font-weight: bold;
}
.cublue {
  color: blue;
  font-weight: bold;
}
td {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.inside-os.report-os .com-title a {
  font-weight: 400;
  color: #4f4d4d;
}
.inside-os.report-os .report .com-table.rt-table {
  border-top: none;
}
a {
  text-decoration: none;
}
.inside-os.report-os .report .com-table td.win,
.inside-os.report-os .report .com-table td.win a {
  color: #2836f4;
}
.inside-os.report-os .report .com-table td.ctr {
  padding: 4px 0;
  text-align: center;
}

.inside-os.report-os .report .com-table td {
  /* padding: 4px 10px 4px 0; */
  text-align: center;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table {
  width: 100%;
}
.inside-os.report-os .report {
  margin-top: 5px;
}
.inside-os.report-os .com-title {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.inside-os .com-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
}
/* .inside-os {
  width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>